<script lang="ts" setup>
interface Props {
  currency?: string
  digits?: number
  price: number | string
  currencySize?: number
}

const { currency = 'HK$', digits = 2, price = 0 } = defineProps<Props>()

const priceAbs = computed<string>(() => {
  const priceArr = price!.toString().split('.')
  return priceArr[0]
})

const priceFloor = computed(() => {
  const priceArr = price!.toString().split('.')
  if (priceArr.length < 2)
    return '00'
  return priceArr[1].slice(0, digits)
})

const attrs = useAttrs()
</script>

<template>
  <div v-bind="attrs">
    <span class="price-currency">{{ currency }}</span>
    <span class="price-integer">{{ priceAbs }}</span>
    <template v-if="digits > 0">
      .<span class="price-decimal">
        {{ priceFloor }}
      </span>
    </template>
  </div>
</template>

<style>

</style>
